import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import {
  Section,
  Title,
  Button as UnstyledButton,
  Image as UnstyledImage,
} from '../components';
import { COL_TEXT, COL_MAIN, COL_MEDIUM } from '../variables';

const Image = styled(UnstyledImage)`
  width: 100%;
  display: block;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    flex-flow: column;
    align-items: center;
  }
`;

const Item = styled.li`
  width: 200px;
  max-width: 25vw;
  text-align: center;

  h2 {
    margin-top: -2rem;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: ${COL_MAIN};
  }
`;

const P = styled.p`
  color: ${COL_MAIN};
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`;

const F = styled(P)`
  min-height: 100px;
`;

const Button = styled(UnstyledButton)`
  color: ${COL_MEDIUM};
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  border: 0;

  &:hover {
    background: ${COL_MEDIUM};
    color: ${COL_TEXT};
  }
`;

export const Contact = () => (
  <Section id="kontakt" color={COL_TEXT}>
    <Title inverted>Kontakt</Title>
    <List>
      <Item>
        <Image name="mail" />
        <h2>E-Mail</h2>
        <F>Wir freuen uns auf Euer Feedback!</F>
        <P>
          <Button inverted href="mailto:info@amreis.de" event="mail">
            〉 info@amreis.de
          </Button>
        </P>
      </Item>
      <Item>
        <Image name="medium" />
        <h2>Medium</h2>
        <F>Teaser unserer neuesten Projekte und Science-Fiction-Beiträge</F>
        <P>
          <Button
            inverted
            href="https://medium.com/a-m-reis"
            event="matze-blog-lower">
            〉 a-m-reis auf Medium
          </Button>
        </P>
      </Item>
      <Item>
        <Image name="facebook" />
        <h2>Facebook</h2>
        <F>
          Ihr habt eine Nachricht für uns? Schreibt uns einfach persönlich an!
        </F>
        <P>
          <Button
            inverted
            href="https://www.facebook.com/anne.reko"
            event="anne-facebook">
            〉 Anne auf Facebook
          </Button>
        </P>
        <P>
          <Button
            inverted
            href="https://www.facebook.com/messju"
            event="matze-facebook">
            〉 Matze auf Facebook
          </Button>
        </P>
      </Item>
      <Item>
        <Image name="twitter" />
        <h2>Twitter</h2>
        <F>Folgt uns für aktuelle Updates</F>
        <P>
          <Button
            inverted
            href="https://twitter.com/Anne_Reko"
            event="anne-twitter">
            〉 @Anne_Reko
          </Button>
        </P>
        <P>
          <Button
            inverted
            href="https://twitter.com/aithir"
            event="matze-twitter">
            〉 @aithir
          </Button>
        </P>
      </Item>
    </List>
  </Section>
);
