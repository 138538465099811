import React from 'react';
import styled from '@emotion/styled';

import { Section, Title, Button, Image } from '../components';
import { BREAKPOINT_LARGE } from '../variables';

const StyledImage = styled(Image)``;

const AuthorContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
`;
const Author = styled.div`
  flex: 1 1 auto;
  display: flex;
  ${StyledImage} {
    flex: 0 0 auto;
    width: 200px;
    height: 200px;
    margin-left: 5rem;
    margin-right: 2rem;
  }
  ${BREAKPOINT_LARGE} {
    ${StyledImage} {
      margin: 0;
    }
  }
  div {
    flex: 1 1 auto;
    width: 100%;
  }
  h3 {
    font-size: 2rem;
    margin-top: 1.5rem;
  }
`;

const Anne = styled(Author)``;

const Matze = styled(Author)`
  h3,
  p,
  a {
    text-align: right;
  }
  ${StyledImage} {
    margin-right: 5rem;
    margin-left: 2rem;
  }
  ${BREAKPOINT_LARGE} {
    ${StyledImage} {
      margin: 0;
    }
  }
`;

export const Authors = () => (
  <Section id="autoren">
    <AuthorContainer>
      <Title>A.M. Reis – das sind wir</Title>

      <Anne name="anne">
        <StyledImage name="anne" />
        <div>
          <h3>Anne Reis</h3>
          <p>
            Reisebegeisterte Redakteurin und Bloggerin aus Leidenschaft.
            Verantwortlich für die Textgestaltung und die Ausarbeitung der
            Charaktere.
          </p>
          <p>
            <Button href="https://cardamonchai.com" event="anne-blog">
              Blog
            </Button>
          </p>
        </div>
      </Anne>
      <Matze name="matze">
        <div>
          <h3>Matthias Reis</h3>
          <p>
            Software-Entwickler und Wissenschafts-Enthusiast mit überschüssiger
            Kreativität und Phantasie. Verantwortlich für das Thema, die
            Handlungsstränge und den roten Faden.
          </p>
          <p>
            <Button href="https://medium.com/a-m-reis" event="matze-blog">
              Medium
            </Button>
          </p>
        </div>
        <StyledImage name="matze" />
      </Matze>
    </AuthorContainer>
  </Section>
);
export default Authors;
